footer {
  background-color: var(--light-green);
  min-height: 300px;
  position: relative;
  margin-top: 15px;
}

footer ul {
  display: flex;
  margin: 0;
  padding: 20px 10%;
  justify-content: center;
}

footer li {
  display: block;
  padding: 10px 20px;
  width: 100%;
  flex: 1;
}

footer a {
  font-size: 1.35em;
}

.copyright-tag {
  position: absolute;
  bottom: 0;
  display: block;
  width: 100%;
  text-align: center;
}

.footer-logo-area {
  flex: 3;
}

.footer-logo {
  max-height: 200px;
  stroke: var(--black);
}
