.initiative-box {
  width: 300px;
  display: inline-block;
  transition: box-shadow var(--anim-len);
  padding: 10px;
  border-top: 5px solid var(--black);
  margin: 20px;
}

.initiative-box:hover {
  box-shadow: 0 0 20px #0004;
}

.initiative-box h2 {
  text-align: center;
  font-family: var(--title);
  font-size: 2em;
}

.initiative-box a {
  margin-left: auto;
  margin-right: auto;
  font-size: 1.3em;
}

.initiative-slide {
  margin-top: 30px;
}

.initiative-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.tag-slide {
  margin-top: 15px;
}

.tag-container {
  display: flex;
  overflow-x: scroll;
}

.tag-box {
  padding: 10px 20px;
  border-radius: 5px;
  background-color: var(--blue);
  border: none;
  font-size: 1.25em;
}

.tag-box + .tag-box {
  margin-left: 10px;
}

.tag-box:hover {
  background-color: var(--light-green);
  color: var(--dark-green);
}

.tag-box.active {
  background-color: var(--light-yellow);
}

.initiative-box:not(.active) {
  display: none;
}
