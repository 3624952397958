.bene-box {
  width: 80%;
  margin: auto;
  position: relative;
}

.bene-box img {
  width: 100%;
}

.initiatives-box {
  width: calc(100% - 50px);
  z-index: 2;
  display: flex;
  align-items: center;
}

.initiatives-box > div {
  overflow-x: scroll;
  height: 30px;
  white-space: nowrap;
  flex-grow: 1;
  position: relative;
}

.initiatives-box > h3 {
  text-align: center;
  margin: 5px;
  display: flex;
  align-items: center;
  height: 30px;
  padding: 0 10px;
  position: relative;
}

.initiative-thumbnail {
  height: 100%;
  display: inline-flex;
  align-items: center;
  position: relative;
}

.initiative-thumbnail + .initiative-thumbnail {
  margin-left: 30px;
}

.effects-box {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 10px;
  padding: 5px, 15px;
  background-color: rgba(255, 255, 255, 0.8);
}

.effects-box p {
  font-size: 1.5em;
  font-weight: 400;
  margin-top: 0;
}

.effects-box p:last-child {
  margin-bottom: 0;
}

.effects-box a {
  --color: var(--white);
  --accent: var(--light-blue);
  --highlight: var(--blue);
}
