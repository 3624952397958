.quote-image {
  width: 100vw;
  height: 300px;
  overflow: hidden;
  position: relative;
  color: var(--white);
}

.quote-image > img {
  width: 100%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  filter: brightness(50%) blur(1px);
  z-index: -1;
}

.quote-image > p {
  font-size: 2.5em;
  text-align: center;
  z-index: 1;
  line-height: 1.5em;
  padding: 5% 20px 0;
  margin: 0;
}

.quote-image > h1 {
  font-size: 4em;
  position: absolute;
  opacity: 0.5;
  margin: 0;
  bottom: 15px;
  right: 15px;
}

.quote-image > div {
  position: absolute;
  bottom: 10px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.quote-image a {
  font-size: 2em;
  color: var(--yellow);
  position: relative;
}

.quote-image a::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 4px;
  background-color: var(--yellow);
  transform-origin: left;
  transform: scale(0);
}

.quote-image a:hover::after {
  transform: scale(1);
  background-color: var(--blue);
  transition: transform var(--anim-len), background-color var(--anim-len);
}

.quote-image > div > a:hover {
  color: var(--light-blue);
  transition: color var(--anim-len);
}

.triple-card {
  display: flex;
  margin: 0 85px;
  flex-wrap: wrap;
  justify-content: space-around;
  border-radius: 15px;

  --main-color: transparent;
  --text-color: inherit;
  --before-opacity: 0;
}

.triple-card > div {
  width: 300px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 30px;
  color: var(--text-color);
}

.split-container > div {
  padding: 10px;
  width: 45%;
  border-radius: 3px;
  position: relative;
  overflow: hidden;
}

.split-container > span {
  border-radius: 3px;
  width: 8px;
  background-color: var(--black);
  opacity: 0.8;
}

.split-container li {
  font-size: 0.8em;
}

.power-source-card img {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0.9;
  transition: opacity var(--anim-len), box-shadow var(--anim-len);
  z-index: -1;
}

.power-sources-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.power-source-card p {
  font-size: 2em;
  transition: box-shadow var(--anim-len);
}

.triple-card p {
  margin: 5px 20px 20px;
  font-size: 1.5em;
}

.split-container p {
  font-size: 1.5em;
}

.prac-card > p {
  font-size: 2em;
}

.triple-card > div + div {
  margin-left: 20px;
}

.triple-card > div:hover {
  --main-color: var(--green);
  --text-color: var(--dark-green);
  --before-opacity: 0.35;
}

.triple-card > div:nth-child(2n):hover {
  --main-color: var(--blue);
  --text-color: var(--dark-blue);
}

.prac-card > img {
  height: 200px;
  margin-right: 10px;
  float: left;
}

.triple-card > div::before {
  content: "";
  background-color: var(--main-color);
  opacity: var(--before-opacity);
  width: 100%;
  height: 75%;
  position: absolute;
  bottom: 0;
  transition: opacity var(--anim-len);
  z-index: -1;
  border-radius: 3px;
}

.triple-card img {
  width: 100%;
  margin-bottom: 10px;
  transition: box-shadow var(--anim-len);
}

.power-source-card:hover img {
  opacity: 0.6;
  box-shadow: 0 0 20px #0004;
}

.triple-card > div:hover img {
  box-shadow: 0 0 20px #0004;
}

.triple-card h2 {
  font-size: 3em;
  margin: 10px;
}

.savings-slide {
  margin: 0 5vw;
}

.split-container {
  display: flex;
  justify-content: space-evenly;
}

.split-container h1 {
  text-align: center;
  font-size: 3em;
}

.split-container ul {
  font-size: 1.5em;
  line-height: 2em;
}

.prac-card {
  z-index: 5;
  position: relative;
}

.power-source-card {
  aspect-ratio: 1;
  position: relative;
  min-width: 300px;
  padding: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 10px
}

.updates-slide {
  width: 100vw;
}

.updates-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.impact-container p {
    font-size: 1.5em;
}
