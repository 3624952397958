.navbox { /* the spacer for the nav box */
  --height: 100px;
  --padding: min(2vw, 50px);

  height: var(--height);
  display: block;
}

.navbox > div { /* the nav box background */
  width: 100vw;
  height: var(--height);
  margin: 0;
  position: fixed;
  top: 0;
  z-index: 10;
  background-color: var(--white);
}

.navbox > div::after {
  content: "";
  position: absolute;
  top: 100%;
  height: 5px;
  width: 100vw;
  left: 0;
  opacity: 0.5;
  background-color: var(--black);
}

.navbox > div > div { /* the main nav box container */
  height: 100%;
  margin: 0 20px;
  display: flex;
  justify-content: space-between;
}

.navbox .logo-box {
  display: flex;
  align-items: center;
  height: 100%;
  padding: 0 var(--padding);
  stroke: var(--black);
  color: var(--black);
  position: relative;
}

.navbox .logo-box:hover {
  color: var(--dark-green);
  stroke: var(--dark-green);
}

.navbox .logo-box::after {
  content: "Green Homes Project";
  font-size: 3em;
  z-index: 1;
}

.navbox .logo-box::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: var(--green);
    opacity: 0;
}

.navbox .logo-box:hover::before {
    opacity: 0.3;
}

.navbox .logo {
  position: relative;
  height: calc(75% - 4px);
  cursor: pointer;
}

.navbox .nav-links {
  height: 100%;
  padding: 0;
  margin: 0;
  display: flex;
  font-size: 1.35em;
  color: var(--black);
}

.navbox .nav-link {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 var(--padding);
  position: relative;
  font-weight: 400;
}

.navbox .nav-link:hover {
  color: var(--dark-green);
}

.navbox .nav-link:hover::before {
  content: "";
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--green);
  opacity: 0.3;
  position: absolute;
}

.dropdown-toggle {
  font-size: 2em;
  width: 40px;
  height: 100%;
  display: none;
  align-items: center;
  justify-content: center;
  transform: rotate(0);
  transition: transform var(--anim-len);
  z-index: 6;
}

.dropdown-toggle.active {
  transform: rotate(720deg);
}

.dd-bars,
.dd-x {
  pointer-events: none;
}

.dropdown-toggle.active .dd-bars {
  display: none;
}

.dropdown-toggle:not(.active) .dd-x {
  display: none;
}

@media screen and (max-width: 1300px) {
  .navbox .logo-box::after {
    content: "GHP";
  }
}

@media screen and (max-width: 800px) {
  .dropdown-toggle {
    display: flex;
  }

  .navbox .nav-links {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
    background-color: var(--white);
    z-index: 5;
    flex-direction: column;
  }

  .navbox .dropdown-toggle.active + .nav-links {
    display: flex;
  }
}
