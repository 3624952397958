.tech-info-img {
  height: 600px;
  margin: 10px;
  aspect-ratio: 1;
  float: left;
}

.info-box {
  max-width: 1500px;
  padding: 10px;
}

.info-box p {
  min-width: 100px;
  font-size: 1.25em;
}

@media screen and (max-width: 800px) {
  .tech-info-img {
    display: none;
  }
}
