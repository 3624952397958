.news-box {
  width: 400px;
  display: inline-block;
  transition: box-shadow var(--anim-len);
  padding: 10px;
  border-top: 5px solid var(--black);
  margin: 20px;
}

.news-box:hover {
  box-shadow: 0 0 20px #0004;
}

.news-box img {
  aspect-ratio: 2/3;
  width: 200px;
}

.news-box h2 {
  font-size: 2.25em;
  font-family: var(--title);
  margin-bottom: 0;
  margin-top: 20px;
}

.news-box h4 {
  margin-top: 10px;
  font-weight: 300;
}

.news-box h3 {
  margin-bottom: 0;
  font-size: 3em;
  margin-top: 20px;
}

.news-box > div {
  display: flex;
  justify-content: space-between;
}

.news-box .tags {
  color: var(--dark-green);
}

.news-slide {
  margin-top: 30px;
}

.news-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
