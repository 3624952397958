@import url("https://fonts.googleapis.com/css2?family=Neuton&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap");

:root {
  --black: #020402;
  --dark-green: #0c633a;
  --green: #16c172;
  --light-green: #72d3b3;
  --green-blue: #3bb8b9;
  --dark-blue: #13466d;
  --blue: #a9d3f7;
  --light-blue: #cde5f3;
  --green-yellow: #88d76a;
  --yellow: #faed62;
  --light-yellow: #f6f2a8;
  --bright-green: #d4efdd;
  --white: #fff;
  --anim-len: 0.1s;
  --title: neuton, serif;
}

.container {
  margin: 0 calc(5vw + 20px);
}

.section {
    display: flex;
    justify-content: center;
}

a {
  text-decoration: none;
  color: inherit;
}

body {
  margin: 0;
  background-color: var(--white);
  font-family: Roboto, sans-serif;
  font-weight: 300;
}

.section-title {
  margin: 15px 75px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 2em;
  float: none;
}

.section-title::after {
  content: "";
  height: 3px;
  flex-grow: 1;
  display: block;
  margin-left: 20px;
  background-color: var(--black);
}

.section-title > span {
  position: relative;
  bottom: 150px;
}

.image-slide {
  margin: 0;
  width: 100vw;
}

.image-slide.slim {
  display: none;
}

@media (max-aspect-ratio: 1) {
  .image-slide.slim {
    display: block;
  }

  .image-slide.wide {
    display: none;
  }
}

.title-text {
  font-family: var(--title);
}

.image-links {
  margin-top: 30px;
  width: 100vw;
}

.image-links > div {
  display: flex;
  justify-content: space-around;
  align-content: center;
  flex-wrap: wrap;
  z-index: 1;
}

.image-links a {
  margin: 50px;
  width: 300px;
  aspect-ratio: 1;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: box-shadow var(--anim-len);
}


.image-links a:hover {
  box-shadow: 0 0 20px #0004;
}

.image-links img {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
  z-index: -1;
}

.image-links a:hover img {
  opacity: 0.6;
}

.image-links p {
  font-size: 2.5em;
  z-index: 2;
}

.more-button {
  --color: var(--black);
  --highlight: var(--dark-green);
  --accent: var(--green);

  color: var(--color);
  border: 2px solid var(--color);
  font-size: 2em;
  padding: 10px;
  position: relative;
  display: inline-block;
}

.more-button:hover {
  color: var(--highlight);
  border: 2px solid var(--highlight);
  transition: color var(--anim-len), border var(--anim-len);
}

.more-button::before {
  content: "";
  left: 0;
  top: 0;
  position: absolute;
  transform: scaleY(0);
  transform-origin: bottom;
  transition: transform var(--anim-len);
  background-color: var(--accent);
  z-index: -1;
  width: 100%;
  height: 100%;
  opacity: 0.3;
}

.more-button:hover::before {
  transform: scaleY(1);
}

.clear {
  clear: both;
}
